<template>
  <mp-link
    :to="$u(linkToUseForHref)"
    class="button"
    :class="[
      buttonStyle,
      color,
      size,
      icon ? 'with-icon' : '',
      buttonLink.cached_url ? 'linked' : '',
    ]"
    :target="targetBlank ? '_blank' : '_self'"
    :rel="targetBlank ? 'noreferrer nofollow' : false"
    :style="backgroundColorCss"
  >
    <div v-if="icon" class="icon">
      <component :is="icon" />
      <slot name="icon" />
    </div>
    <span class="label">
      {{ label }}
      <slot name="label" />
    </span>
  </mp-link>
</template>

<script>
import IconCrossThick from '@/assets/icons/cross-thick.svg'

export default {
  name: 'Button',
  components: {
    IconCrossThick,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: () => '',
    },
    buttonStyle: {
      type: String,
      required: false,
      default: () => '',
    },
    color: {
      type: String,
      required: false,
      default: () => '',
    },
    colorHex: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: () => '',
    },
    // Storyblok link object
    link: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // This one can be used instead of "link" if you pass a string
    linkAsString: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: () => '',
    },
    targetBlank: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    /**
     * Makes it possible to force use a link from a parent,
     * i.e. a Hero.
     */
    inheritedLink: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    linkToUseForHref () {
      return this.linkAsString !== null ? this.linkAsString : this.link
    },
    buttonLink() {
      return this.inheritedLink?.cached_url ? this.inheritedLink : this.link
    },
    backgroundColorCss() {
      let color = this.color
      if (this.colorHex) {
        color = this.colorHex
        if (color.substring(0, 1) !== '#') {
          color = '#' + color
        }
        return { backgroundColor: color }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  .icon {
    svg {
      fill: var(--fill-color, white);
    }
  }

  &.linked {
    pointer-events: all;
  }
}
</style>
