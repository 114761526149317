var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mp-link',{staticClass:"button",class:[
    _vm.buttonStyle,
    _vm.color,
    _vm.size,
    _vm.icon ? 'with-icon' : '',
    _vm.buttonLink.cached_url ? 'linked' : '',
  ],style:(_vm.backgroundColorCss),attrs:{"to":_vm.$u(_vm.linkToUseForHref),"target":_vm.targetBlank ? '_blank' : '_self',"rel":_vm.targetBlank ? 'noreferrer nofollow' : false}},[(_vm.icon)?_c('div',{staticClass:"icon"},[_c(_vm.icon,{tag:"component"}),_vm._v(" "),_vm._t("icon")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),_vm._t("label")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }